import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { UserInfo } from "../../api/model";
import * as api from "../../api/api";

export default function AdminUsers() {
  const [users, setUsers] = useState<UserInfo[] | undefined>();

  function updateUsers() {
    api.getUsers().then(setUsers);
  }

  useEffect(updateUsers, []);

  function toggleAdmin(user: UserInfo) {
    api.updateUser(user.id, !user.adminPowerEnabled).then(updateUsers);
  }

  if (users === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Username</th>
          <th>SOI admin</th>
          <th>Creativity admin</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id}>
            <td>{user.username}</td>
            <td>
              {user.soiAdmin ? (
                <i
                  className="bi bi-check-circle"
                  style={{ color: "green" }}
                ></i>
              ) : (
                <i className="bi bi-dash-circle" style={{ color: "red" }}></i>
              )}
            </td>
            <td>
              {user.adminPowerEnabled ? (
                <i
                  className="bi bi-check-circle clickable"
                  style={{ color: "green" }}
                  onClick={() => toggleAdmin(user)}
                ></i>
              ) : (
                <i
                  className="bi bi-dash-circle clickable"
                  style={{ color: "red" }}
                  onClick={() => toggleAdmin(user)}
                ></i>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
