import { Tournament } from "../../api/model";
import { Tab, Tabs } from "react-bootstrap";
import TournamentRanking from "./TournamentRanking";
import TournamentMapDetails from "./TournamentMapDetails";

export default function TournamentFinishedDetails(props: {
  tournament: Tournament;
}) {
  return (
    <Tabs>
      <Tab eventKey="ranking" title="Ranking">
        <TournamentRanking tournament={props.tournament} />
      </Tab>
      {props.tournament.maps.map((m) => (
        <Tab eventKey={`map-${m.id}`} key={m.id} title={m.mapName}>
          <TournamentMapDetails
            tournament={props.tournament}
            tournamentMap={m}
          />
        </Tab>
      ))}
    </Tabs>
  );
}
