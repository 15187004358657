import {
  GameResult,
  Tournament,
  TournamentBot,
  TournamentGame,
  TournamentGameResult,
  TournamentMap,
} from "../../api/model";
import { Link } from "react-router-dom";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { useState } from "react";
import { gameResultToClass } from "../../utils/game-utils";

export default function TournamentMapDetails(props: {
  tournament: Tournament;
  tournamentMap: TournamentMap;
}) {
  const [bot1, setBot1] = useState<number | null>(null);
  const [bot2, setBot2] = useState<number | null>(null);

  const idToBot = new Map<number, TournamentBot>();
  const games = new Map<number, Map<number, TournamentGame>>();

  props.tournamentMap.games.forEach((game) => {
    idToBot.set(game.bot1.id, game.bot1);
    idToBot.set(game.bot2.id, game.bot2);
    if (!games.has(game.bot1.id)) {
      games.set(game.bot1.id, new Map());
    }
    if (!games.has(game.bot2.id)) {
      games.set(game.bot2.id, new Map());
    }
    games.get(game.bot1.id)?.set(game.bot2.id, game);
    games.get(game.bot2.id)?.set(game.bot1.id, game);
  });

  const currentGame =
    bot1 != null && bot2 != null ? games.get(bot1)?.get(bot2) : null;

  function gameResult(): string {
    switch (currentGame?.result) {
      case TournamentGameResult.DRAW:
        return `Draw`;
      case TournamentGameResult.PENDING:
        return `Pending`;
      case TournamentGameResult.WIN1:
        return `${currentGame.bot1.name} won`;
      case TournamentGameResult.WIN2:
        return `${currentGame.bot2.name} won`;
    }
    return "¯\\_(ツ)_/¯";
  }

  function relativeGameResultToClass(game: TournamentGame) {
    switch (game.result) {
      case TournamentGameResult.DRAW:
        return gameResultToClass(GameResult.DRAW);
      case TournamentGameResult.PENDING:
        return gameResultToClass(GameResult.PENDING);
      case TournamentGameResult.WIN1:
        return gameResultToClass(
          game.bot1.id == bot1 ? GameResult.WIN : GameResult.LOSS
        );
      case TournamentGameResult.WIN2:
        return gameResultToClass(
          game.bot1.id == bot1 ? GameResult.LOSS : GameResult.WIN
        );
    }
  }

  return (
    <div className="my-3">
      <div>Score weight: {props.tournamentMap.scoreWeight}</div>
      <div>
        <Link to={`/play/${props.tournamentMap.mapName}`}>Play map</Link>
      </div>
      <Row>
        <Col sm={4} className="my-3">
          <ListGroup>
            {Array.from(games.keys()).map((botId1) => (
              <ListGroup.Item
                key={botId1}
                action
                active={bot1 == botId1}
                onClick={() => {
                  setBot1(botId1);
                  if (bot2 != null && !games.get(botId1)?.has(bot2)) {
                    setBot2(null);
                  }
                }}
              >
                {idToBot.get(botId1)?.name} ({idToBot.get(botId1)?.rating})
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col sm={4} className="my-3">
          {bot1 != null && (
            <ListGroup>
              {Array.from(games.get(bot1)?.keys() || []).map((botId2) => (
                <ListGroup.Item
                  key={botId2}
                  action
                  active={bot2 == botId2}
                  onClick={() => {
                    setBot2(botId2);
                  }}
                  className={relativeGameResultToClass(
                    games.get(bot1)?.get(botId2)!
                  )}
                >
                  {idToBot.get(botId2)?.name} ({idToBot.get(botId2)?.rating})
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col sm={4} className="my-3">
          {currentGame != null && (
            <Card>
              <Card.Header>{gameResult()}</Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <a
                    target="_blank"
                    href={`/visualization/colorclaim/index.html#url=/api/filestorage/${currentGame.gameLog}/`}
                  >
                    Game 1
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
}
