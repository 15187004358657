import { Tournament, TournamentMap } from "../../api/model";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import * as api from "../../api/api";
import { useState } from "react";
import {
  normalizeMapName,
  validateMapName,
} from "../../utils/map-combinations";

export default function TournamentMapEdit(props: {
  tournament: Tournament;
  tournamentMap: TournamentMap;
  onUpdate: () => void;
}) {
  const [mapName, setMapName] = useState<string>(props.tournamentMap.mapName);
  const [mapSeed, setMapSeed] = useState<number>(props.tournamentMap.mapSeed);
  const [mapScoreWeight, setMapScoreWeight] = useState<number>(
    props.tournamentMap.scoreWeight
  );

  function saveMap() {
    api
      .updateTournamentMap(
        props.tournament.id,
        props.tournamentMap.id,
        normalizeMapName(mapName)!!,
        mapSeed,
        mapScoreWeight
      )
      .then(props.onUpdate);
  }

  function deleteMap() {
    api
      .deleteTournamentMap(props.tournament.id, props.tournamentMap.id)
      .then(props.onUpdate);
  }

  return (
    <Row className="mb-3 align-items-end">
      <FormGroup as={Col} md={6} className={"mb-3"}>
        <Form.Label>Map name</Form.Label>
        <Form.Control
          value={mapName}
          onChange={(e) => setMapName(e.currentTarget.value)}
          isInvalid={validateMapName(mapName) == null}
          list="validmapnames"
        />
      </FormGroup>
      <FormGroup as={Col} md={2} className={"mb-3"}>
        <Form.Label>Map seed</Form.Label>
        <Form.Control
          value={mapSeed}
          type="number"
          onChange={(e) => setMapSeed(parseInt(e.currentTarget.value))}
        />
      </FormGroup>
      <FormGroup as={Col} md={2} className={"mb-3"}>
        <Form.Label>Score weight</Form.Label>
        <Form.Control
          value={mapScoreWeight}
          type="number"
          min="0"
          onChange={(e) => setMapScoreWeight(parseFloat(e.currentTarget.value))}
        />
      </FormGroup>
      <Col md={1} className={"mb-3"}>
        <Button
          variant="success"
          onClick={saveMap}
          disabled={
            validateMapName(mapName) == null ||
            (mapName == props.tournamentMap.mapName &&
              mapSeed == props.tournamentMap.mapSeed &&
              mapScoreWeight == props.tournamentMap.scoreWeight)
          }
        >
          Save
        </Button>
      </Col>
      <Col md={1} className={"mb-3"}>
        <Button variant="danger" onClick={deleteMap}>
          Delete
        </Button>
      </Col>
    </Row>
  );
}
