import { Button, Form } from "react-bootstrap";
import React, { useState } from "react";
import { Bot } from "../../api/model";
import * as api from "../../api/api";
import {
  guessLanguageByExtension,
  languageCodeToLanguage,
  LANGUAGES,
} from "../../utils/language-utils";

export default function NewBotVersionForm(props: {
  bot: Bot;
  onVersionSubmit: (id: number) => void;
}) {
  const [language, setLanguage] = useState<string>("cpp");
  const [sourceCode, setSourceCode] = useState<string>("");

  function sourceCodeUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (!files || files.length == 0) {
      return;
    }
    const file = files[0];
    const fileParts = file.name.split(".");
    const guess = guessLanguageByExtension(fileParts[fileParts.length - 1]);
    if (guess) {
      setLanguage(guess);
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target?.result?.toString() || "";
      setSourceCode(text);
    };
    reader.readAsText(file);
  }

  function submitNewBotVersion() {
    if (sourceCode.length < 10) {
      return;
    }
    api
      .uploadBotVersion(props.bot.id, sourceCode, language)
      .then((version) => props.onVersionSubmit(version.id))
      .then(() => setSourceCode(""));
  }

  return (
    <Form className="mt-3" action="#">
      <Form.Group className="mb-3">
        <Form.Label>Language</Form.Label>
        <Form.Select
          value={language}
          onChange={(e) => setLanguage(e.currentTarget.value)}
        >
          {LANGUAGES.map((language) => (
            <option key={language} value={language}>
              {languageCodeToLanguage(language)}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Source file</Form.Label>
        <Form.Control type="file" onChange={sourceCodeUpload} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Source Code</Form.Label>
        <Form.Control
          as="textarea"
          rows={7}
          value={sourceCode}
          onChange={(e) => setSourceCode(e.currentTarget.value)}
        />
      </Form.Group>
      <Button disabled={sourceCode?.length < 10} onClick={submitNewBotVersion}>
        Submit new version
      </Button>
    </Form>
  );
}
