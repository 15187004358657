import { useNavigate, useOutletContext } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import * as api from "../../api/api";
import { Bot } from "../../api/model";
import { useState } from "react";
import {
  botCodeStatusToIcon,
  challengePolicyToIcon,
  tournamentToIcon,
} from "../../utils/bot-status-icons";
import { MyBotsOutletContext } from "./MyBots";
import { useLoginInfo } from "../../App";

export default function MyBotsOverview() {
  const { onMyBotsChange, bots } = useOutletContext<MyBotsOutletContext>();
  const navigate = useNavigate();
  const loginInfo = useLoginInfo();
  const [newBotEnabled, setNewBotEnabled] = useState<boolean>(false);

  function navigateToBot(bot: Bot) {
    navigate(`/my-bots/${bot.id}`);
  }

  function addNewBot() {
    api
      .addBot()
      .then((bot) => {
        navigateToBot(bot);
      })
      .then(onMyBotsChange);
    setNewBotEnabled(false);
  }

  function addNewBotWidget(): JSX.Element {
    if (bots.length < 1) {
      return (
        <Button size="sm" onClick={addNewBot}>
          Add new bot
        </Button>
      );
    } else if (loginInfo.admin) {
      if (newBotEnabled) {
        return (
          <div>
            <div>
              You have already created {bots.length} bot
              {bots.length > 1 ? "s" : ""}. Do you really want to create another
              bot?
            </div>
            <div className="mt-2">
              <Button size="sm" onClick={addNewBot}>
                Confirm
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div>
          <Button size="sm" onClick={() => setNewBotEnabled(true)}>
            Add new bot
          </Button>
        </div>
      );
    } else {
      return <div />;
    }
  }

  return (
    <div>
      <Table hover striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Rating</th>
            <th>Code</th>
            <th>Tournament</th>
            <th>Public</th>
          </tr>
        </thead>
        <tbody>
          {bots.map((bot) => (
            <tr
              className="clickable"
              key={bot.id}
              onClick={() => navigateToBot(bot)}
            >
              <td>{bot.name}</td>
              <td>{bot.rating}</td>
              <td>{botCodeStatusToIcon(bot, true)}</td>
              <td>{tournamentToIcon(bot)}</td>
              <td>{challengePolicyToIcon(bot)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {addNewBotWidget()}
    </div>
  );
}
