import { Link, NavLink } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import * as api from "../api/api";
import { CSSProperties } from "react";
import { useLoginInfo } from "../App";

export default function (props: { onLogout: () => void }) {
  function doLogout() {
    api.logout().then(() => {
      props.onLogout();
    });
  }

  const loginInfo = useLoginInfo();

  const activeLinkStyle: CSSProperties = {
    color: "white",
  };

  return (
    <Navbar expand="md" variant="dark" bg="soi-blue" className="mb-4">
      <Container>
        <Navbar.Brand>
          <Link to="/">SOI Creativity Task</Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-between ms-3">
          <Nav>
            <div className="nav-link">
              <NavLink
                to="/play"
                style={({ isActive }) => (isActive ? activeLinkStyle : {})}
              >
                Play
              </NavLink>
            </div>
            <div className="nav-link">
              <NavLink
                to="/tournaments"
                style={({ isActive }) => (isActive ? activeLinkStyle : {})}
              >
                Tournaments
              </NavLink>
            </div>
            <div className="nav-link">
              <NavLink
                to="/ranking"
                style={({ isActive }) => (isActive ? activeLinkStyle : {})}
              >
                Ranking
              </NavLink>
            </div>
            <div className="nav-link">
              <NavLink
                to="/my-bots"
                style={({ isActive }) => (isActive ? activeLinkStyle : {})}
              >
                My Bots
              </NavLink>
            </div>
            {loginInfo.admin && (
              <div className="nav-link">
                <NavLink
                  to="/admin"
                  style={({ isActive }) => (isActive ? activeLinkStyle : {})}
                >
                  Admin
                </NavLink>
              </div>
            )}
          </Nav>
          <Navbar.Text>
            Hi {loginInfo.username} (
            <span className="clickable" onClick={doLogout}>
              Logout
            </span>
            )
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
