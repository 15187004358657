import { useEffect, useState } from "react";
import { Bot, TournamentOverview, TournamentStatus } from "../../api/model";
import * as api from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import { useLoginInfo } from "../../App";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";

export default function Tournaments() {
  const [tournaments, setTournaments] = useState<
    TournamentOverview[] | undefined
  >();
  const [bots, setBots] = useState<Bot[]>([]);
  const loginInfo = useLoginInfo();
  const navigate = useNavigate();

  useEffect(() => {
    api.getMyBots().then(setBots);
    api.getTournaments().then(setTournaments);
  }, []);

  function addNewTournament() {
    api
      .addTournament(
        "New tournament",
        new Date("2100-01-01T00:00:00Z"),
        true,
        false
      )
      .then((tournament) => navigate(`/tournaments/${tournament.id}`));
  }

  if (tournaments === undefined) {
    return <div>Loading...</div>;
  }

  const registeredBots = bots.filter((bot) => bot.participateInTournaments);
  const notRegisteredBots = bots.filter((bot) => !bot.participateInTournaments);
  const upcomingTournaments = tournaments.filter(
    (tournament) => tournament.status == TournamentStatus.SCHEDULED
  );
  const pastTournaments = tournaments.filter(
    (tournament) => tournament.status != TournamentStatus.SCHEDULED
  );
  pastTournaments.reverse();

  return (
    <div>
      <h1>Tournaments</h1>
      <Row>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Header>Registered bots</Card.Header>
            {registeredBots.length == 0 && (
              <Card.Body>
                <Card.Text>
                  <i
                    title="You don't have any bots registered"
                    className="bi bi-exclamation-circle me-2"
                    style={{ color: "red" }}
                  ></i>{" "}
                  You don't have any bots registered for the next tournament.
                </Card.Text>
              </Card.Body>
            )}
            {registeredBots.length > 0 && (
              <ListGroup variant="flush">
                {registeredBots.map((bot) => (
                  <ListGroup.Item key={bot.id}>
                    <Link to={`/my-bots/${bot.id}`}>{bot.name}</Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Card>
          <Card className="mb-3">
            <Card.Header>Not registered bots</Card.Header>
            {notRegisteredBots.length == 0 && (
              <Card.Body>
                <Card.Text>
                  All of your bots are registered for the next tournament.
                </Card.Text>
              </Card.Body>
            )}
            {notRegisteredBots.length > 0 && (
              <ListGroup variant="flush">
                {notRegisteredBots.map((bot) => (
                  <ListGroup.Item key={bot.id}>
                    <Link to={`/my-bots/${bot.id}`}>{bot.name}</Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Header>Upcoming tournaments</Card.Header>
            {upcomingTournaments.length == 0 && (
              <Card.Body>
                <Card.Text>
                  There are no upcoming tournaments scheduled.
                </Card.Text>
              </Card.Body>
            )}
            {upcomingTournaments.length > 0 && (
              <ListGroup variant="flush">
                {upcomingTournaments.map((tournament) => (
                  <ListGroup.Item key={tournament.id}>
                    <Link to={`/tournaments/${tournament.id}`}>
                      <>
                        {tournament.start.toLocaleString("de-CH")}:{" "}
                        {tournament.name}
                      </>
                    </Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
            {loginInfo.admin && (
              <Card.Footer className="text-end">
                <Button size="sm" onClick={addNewTournament}>
                  Add new tournament
                </Button>
              </Card.Footer>
            )}
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Header>Past tournaments</Card.Header>
            {pastTournaments.length == 0 && (
              <Card.Body>
                <Card.Text>There are no past tournaments.</Card.Text>
              </Card.Body>
            )}
            {pastTournaments.length > 0 && (
              <ListGroup variant="flush">
                {pastTournaments.map((tournament) => (
                  <ListGroup.Item key={tournament.id}>
                    <Link to={`/tournaments/${tournament.id}`}>
                      <>
                        {tournament.start.toLocaleString("de-CH")}:{" "}
                        {tournament.name}
                      </>
                    </Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
