import { Tab, Tabs } from "react-bootstrap";
import { useLoginInfo } from "../../App";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function Admin() {
  const loginInfo = useLoginInfo();
  const navigate = useNavigate();
  const location = useLocation();

  if (!loginInfo.admin) {
    return <div className="text-danger">Not allowed</div>;
  }

  function onTabChanged(key: string | null) {
    if (!key) {
      navigate("/admin");
    } else {
      navigate(`/admin/${key}`);
    }
  }

  const activeKey = location.pathname
    .replace("/admin/", "")
    .replace("/admin", "tasks");
  return (
    <Tabs activeKey={activeKey} onSelect={onTabChanged}>
      <Tab eventKey="tasks" title="Tasks">
        <Outlet />
      </Tab>
      <Tab eventKey="users" title="Users">
        <Outlet />
      </Tab>
      <Tab eventKey="mapping" title="Bot mapping">
        <Outlet />
      </Tab>
    </Tabs>
  );
}
