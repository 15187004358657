import { ChallengeStatus } from "../api/model";

export function challengeStatusToIcon(
  status: ChallengeStatus,
  color: boolean
): JSX.Element {
  switch (status) {
    case ChallengeStatus.WAITING:
      return (
        <i
          title="waiting"
          className="bi bi-info-circle"
          style={color ? { color: "gray" } : undefined}
        ></i>
      );
    case ChallengeStatus.REJECTED:
      return (
        <i
          title="rejected"
          className="bi bi-x-circle"
          style={color ? { color: "red" } : undefined}
        ></i>
      );
    case ChallengeStatus.ACCEPTED:
      return (
        <i
          title="accepted"
          className="bi bi-check-circle"
          style={color ? { color: "green" } : undefined}
        ></i>
      );
  }
}
