import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { SimulationTask } from "../../api/model";
import * as api from "../../api/api";

export default function AdminTasksSimulation() {
  const [tasks, setTasks] = useState<SimulationTask[] | undefined>();

  function updateTasks() {
    api.getSimulationTasks().then(setTasks);
  }

  useEffect(updateTasks, []);

  if (tasks === undefined) {
    return <div>Loading...</div>;
  }

  if (tasks.length == 0) {
    return <div>No tasks in queue</div>;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>ID</th>
          <th>State</th>
          <th>Claim timestamp</th>
          <th>Name 1</th>
          <th>Name 2</th>
          <th>Artifact 1</th>
          <th>Artifact 2</th>
          <th>Language 1</th>
          <th>Language 2</th>
          <th>Map name</th>
          <th>Map seed</th>
        </tr>
      </thead>
      <tbody>
        {tasks.map((task) => (
          <tr key={task.id}>
            <td>{task.id}</td>
            <td>{task.state}</td>
            <td>{task.claimTimestamp?.toLocaleString("de-CH") ?? "-"}</td>
            <td>{task.name1}</td>
            <td>{task.name2}</td>
            <td>
              <a
                target="_blank"
                href={`/api/filestorage/${task.artifactPath1}/`}
              >
                Code
              </a>
            </td>
            <td>
              <a
                target="_blank"
                href={`/api/filestorage/${task.artifactPath2}/`}
              >
                Code
              </a>
            </td>
            <td>{task.sourceCodeLanguage1}</td>
            <td>{task.sourceCodeLanguage2}</td>
            <td>{task.mapName}</td>
            <td>{task.mapSeed}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
