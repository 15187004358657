import {
  Bot,
  BotVersion,
  BotVersionDetails,
  BotVersionStatus,
} from "../../api/model";
import { useEffect, useState } from "react";
import * as api from "../../api/api";
import { Col, Form, ListGroup, Row } from "react-bootstrap";
import { languageCodeToLanguage } from "../../utils/language-utils";
import { codeStatusToIcon } from "../../utils/bot-status-icons";
import { Link } from "react-router-dom";
import {
  gameResultToClass,
  gameResultToDescription,
} from "../../utils/game-utils";

export default function VersionDetails(props: {
  bot: Bot;
  botVersion: BotVersion;
}) {
  const [versionDetails, setVersionDetails] = useState<
    BotVersionDetails | undefined
  >();
  const [showSource, setShowSource] = useState(false);
  const [sourceCode, setSourceCode] = useState<string | null>(null);
  const [showTournamentGames, setShowTournamentGames] =
    useState<boolean>(false);

  useEffect(() => {
    api
      .getBotVersion(props.bot.id, props.botVersion.id)
      .then(setVersionDetails);
  }, [props.bot.id, props.botVersion.id]);

  function toggleSource() {
    if (!sourceCode) {
      api.getFileStoreString(versionDetails!.sourcePath).then(setSourceCode);
    }
    setShowSource(!showSource);
  }

  if (versionDetails === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ListGroup variant="flush">
        <ListGroup.Item>Version: {versionDetails.version}</ListGroup.Item>
        <ListGroup.Item>
          Submitted: {versionDetails.created.toLocaleString("de-CH")}
        </ListGroup.Item>
        <ListGroup.Item>
          Language: {languageCodeToLanguage(versionDetails.sourceCodeLanguage)}
        </ListGroup.Item>
        <ListGroup.Item>
          Status: {codeStatusToIcon(versionDetails.status, true)}{" "}
          {versionDetails.status}
        </ListGroup.Item>
        {(versionDetails.status === BotVersionStatus.FAILED_COMPILATION ||
          versionDetails.status === BotVersionStatus.FAILED_TESTING) && (
          <ListGroup.Item>
            Error Message
            <br />
            <pre>{versionDetails.errorMessage}</pre>
          </ListGroup.Item>
        )}
        <ListGroup.Item>
          <span onClick={toggleSource} className="clickable">
            Source Code
          </span>
          <br />
          {showSource && <pre>{sourceCode}</pre>}
        </ListGroup.Item>
        {versionDetails.games.filter((game) => game.tournamentGame).length >
          0 && (
          <ListGroup.Item>
            <Form>
              <Form.Check
                type="checkbox"
                checked={showTournamentGames}
                onChange={(e) =>
                  setShowTournamentGames(e.currentTarget.checked)
                }
                label="Show tournament games"
              />
            </Form>
          </ListGroup.Item>
        )}
        {versionDetails.games
          .filter((game) => showTournamentGames || !game.tournamentGame)
          .map((game) => (
            <ListGroup.Item
              key={game.id}
              className={gameResultToClass(game.result) + " no-color-link"}
            >
              <Row>
                <Col sm={4}>
                  {game.created.toLocaleString("de-CH")}{" "}
                  {game.tournamentGame && (
                    <i className="bi bi-diagram-2" title="Tournament game"></i>
                  )}
                </Col>
                <Col sm={4}>
                  <Link to={`/play/${game.opponentId}`}>
                    {game.opponentName}
                  </Link>
                </Col>
                <Col sm={4}>
                  <Link to={`/play/${game.mapName}`}>{game.mapName}</Link>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>{gameResultToDescription(game.result)}</Col>
                <Col sm={4}>
                  {game.gameLog && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`/visualization/colorclaim/index.html#url=/api/filestorage/${game.gameLog}/`}
                    >
                      Game Visualization
                    </a>
                  )}
                </Col>
                <Col sm={4} />
              </Row>
              {game.crash && (
                <Row>
                  <Col sm={4} />
                  <Col sm={4}>{game.crash}</Col>
                  <Col sm={4}>Map Seed: {game.mapSeed}</Col>
                </Row>
              )}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
}
