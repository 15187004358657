import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import * as api from "../../api/api";
import { Bot } from "../../api/model";
import { Tab, Tabs } from "react-bootstrap";
import { useLoginInfo } from "../../App";

export interface MyBotsOutletContext {
  onMyBotsChange: () => void;
  bots: Bot[];
}

export default function MyBots() {
  const [bots, setBots] = useState<Bot[] | undefined>();
  const params = useParams();
  const navigate = useNavigate();
  const loginInfo = useLoginInfo();

  function onMyBotsChange() {
    api.getMyBots().then(setBots);
  }

  function onTabChanged(key: string | null) {
    if (!key) {
      navigate("/my-bots");
    } else {
      navigate(`/my-bots/${key}`);
    }
  }

  useEffect(() => {
    onMyBotsChange();
  }, []);

  if (bots === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1 className="mb-3">My bots</h1>
      <Tabs activeKey={params.botId || ""} onSelect={onTabChanged}>
        <Tab eventKey="" title="Overview">
          <Outlet context={{ onMyBotsChange, bots }} />
        </Tab>
        {bots.map((bot) => (
          <Tab key={bot.id} eventKey={bot.id} title={bot.name}>
            <Outlet context={{ onMyBotsChange, bots }} />
          </Tab>
        ))}
        {loginInfo.admin &&
          params.botId &&
          bots.filter((bot) => bot.id.toString() == params.botId).length ==
            0 && (
            <Tab
              title={<span className="text-danger">Admin view of bot</span>}
              eventKey={params.botId}
            >
              <Outlet context={{ onMyBotsChange, bots }} />
            </Tab>
          )}
      </Tabs>
    </div>
  );
}
