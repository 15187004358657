import { ChallengePolicy } from "../api/model";

export function challengePolicyToDescription(
  challengePolicy: ChallengePolicy
): string {
  if (challengePolicy == ChallengePolicy.ALL) {
    return "anyone can play against this bot";
  }
  if (challengePolicy == ChallengePolicy.CONFIRM) {
    return "you have to confirm before someone can play against this bot";
  }
  return "no one can play against this bot";
}
