import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

export default function (props: { onLogout: () => void }) {
  return (
    <>
      <Header onLogout={props.onLogout}></Header>
      <Container>
        <Outlet />
      </Container>
    </>
  );
}
