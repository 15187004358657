import React, { useContext, useEffect, useState } from "react";
import * as api from "./api/api";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginInfo } from "./api/model";
import Root from "./pages/Root";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import MyBots from "./pages/my-bots/MyBots";
import MyBotDetails from "./pages/my-bots/MyBotDetails";
import AdminUsers from "./pages/admin/AdminUsers";
import MyBotsOverview from "./pages/my-bots/MyBotsOverview";
import Ranking from "./pages/ranking/Ranking";
import TournamentDetails from "./pages/tournament-details/TournamentDetails";
import Tournaments from "./pages/tournaments/Tournaments";
import AdminBotMapping from "./pages/admin/AdminBotMapping";
import Admin from "./pages/admin/Admin";
import AdminTasks from "./pages/admin/AdminTasks";
import PlayBot from "./pages/play/PlayBot";

const LoginInfoContext = React.createContext<LoginInfo>({
  authenticated: false,
});

export function useLoginInfo(): LoginInfo {
  return useContext(LoginInfoContext);
}

function App() {
  let [loginInfo, setLoginInfo] = useState<LoginInfo>({ authenticated: false });

  useEffect(() => {
    let maybeLoginInfo = localStorage.getItem("loginInfo");
    if (maybeLoginInfo) {
      setLoginInfo(JSON.parse(maybeLoginInfo));
    }
    api.authMe().then((loginInfo) => {
      localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
      setLoginInfo(loginInfo);
    });
  }, []);

  if (!loginInfo.username) {
    return <Login />;
  }

  return (
    <LoginInfoContext.Provider value={loginInfo}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Root onLogout={() => setLoginInfo({ authenticated: false })} />
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="my-bots" element={<MyBots />}>
              <Route path=":botId" element={<MyBotDetails />} />
              <Route index element={<MyBotsOverview />} />
            </Route>
            <Route path="play" element={<PlayBot />} />
            <Route path="play/:botid" element={<PlayBot />} />
            <Route
              path="tournaments/:tournamentId"
              element={<TournamentDetails />}
            />
            <Route path="tournaments" element={<Tournaments />} />
            <Route path="ranking" element={<Ranking />} />
            <Route path="admin" element={<Admin />}>
              <Route path="tasks" element={<AdminTasks />} />
              <Route path="users" element={<AdminUsers />} />
              <Route path="mapping" element={<AdminBotMapping />} />
              <Route index element={<AdminTasks />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </LoginInfoContext.Provider>
  );
}

export default App;
