import AdminTasksCompile from "./AdminTasksCompile";
import AdminTasksSimulation from "./AdminTasksSimulation";

export default function AdminTasks() {
  return (
    <>
      <div className="my-3">
        <h2>Compile tasks</h2>
        <AdminTasksCompile />
      </div>
      <div className="my-3">
        <h2>Simulation tasks</h2>
        <AdminTasksSimulation />
      </div>
    </>
  );
}
