export interface LoginInfo {
  authenticated: boolean;
  username?: string;
  userid?: number;
  admin?: boolean;
}

export interface Bot {
  id: number;
  name: string;
  participateInTournaments: boolean;
  challengePolicy: ChallengePolicy;
  rating: number;
  activeVersion?: BotVersion;
  versions: BotVersion[];
}

export interface BotVersion {
  id: number;
  version: number;
  created: Date;
  status: BotVersionStatus;
}

export interface BotVersionDetails {
  id: number;
  version: number;
  created: Date;
  status: BotVersionStatus;
  sourcePath: string;
  sourceCodeLanguage: string;
  errorMessage?: string;
  games: Game[];
}

export interface Game {
  id: number;
  mapName: number;
  mapSeed: number;
  created: Date;
  opponentId: number;
  opponentName: string;
  tournamentGame: boolean;
  gameLog?: string;
  crash?: string;
  result: GameResult;
}

export interface GameBot {
  id: number;
  name: string;
  challengePolicy: ChallengePolicy;
  rating: number;
  myBot: boolean;
}

export interface NewGame {
  needsConfirmation: boolean;
}

export interface Confirmation {
  id: number;
  mapName: String;
  challenger: GameBot;
  challenged: GameBot;
  status: ChallengeStatus;
}

export interface TournamentOverview {
  id: number;
  name: string;
  start: Date;
  end?: Date;
  status: TournamentStatus;
}

export interface Tournament {
  id: number;
  name: string;
  start: Date;
  end?: Date;
  hidden: boolean;
  hiddenRanking: boolean;
  status: TournamentStatus;
  botCount: number;
  maps: TournamentMap[];
  ranking: TournamentRanking[];
}

export interface TournamentMap {
  id: number;
  mapName: string;
  mapSeed: number;
  scoreWeight: number;
  games: TournamentGame[];
}

export interface TournamentRanking {
  bot: TournamentBot;
  score: number;
}

export interface TournamentGame {
  bot1: TournamentBot;
  bot2: TournamentBot;
  gameLog?: string;
  result: TournamentGameResult;
}

export interface TournamentBot {
  id: number;
  name: string;
  challengePolicy: ChallengePolicy;
  rating: number;
  myBot: boolean;
}

export interface BotMapping {
  botId: number;
  botName: string;
  userId: number;
  username: string;
}

export interface UserInfo {
  id: number;
  username: string;
  soiAdmin: boolean;
  adminPowerEnabled: boolean;
}

export interface CompileTask {
  id: number;
  claimTimestamp: Date | null;
  state: TaskState;
  sourceCodeLanguage: string;
  sourceCodePath: string;
}

export interface SimulationTask {
  id: number;
  claimTimestamp: Date | null;
  state: TaskState;
  name1: string;
  name2: string;
  artifactPath1: string;
  artifactPath2: string;
  sourceCodeLanguage1: string;
  sourceCodeLanguage2: string;
  mapName: string;
  mapSeed: number;
}

export enum GameResult {
  WIN = "WIN",
  DRAW = "DRAW",
  LOSS = "LOSS",
  PENDING = "PENDING",
}

export enum TournamentGameResult {
  WIN1 = "WIN1",
  WIN2 = "WIN2",
  DRAW = "DRAW",
  PENDING = "PENDING",
}

export enum ChallengePolicy {
  ALL = "ALL",
  CONFIRM = "CONFIRM",
  NONE = "NONE",
}

export enum ChallengeStatus {
  WAITING = "WAITING",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
}

export enum BotVersionStatus {
  NEW = "NEW",
  COMPILING = "COMPILING",
  TESTING = "TESTING",
  FAILED_COMPILATION = "FAILED_COMPILATION",
  FAILED_TESTING = "FAILED_TESTING",
  READY = "READY",
}

export enum TournamentStatus {
  SCHEDULED = "SCHEDULED",
  RUNNING = "RUNNING",
  FINISHED = "FINISHED",
}

export enum TaskState {
  WAITING = "WAITING",
  RUNNING = "RUNNING",
  PROCESSING = "PROCESSING",
  FINISHED = "FINISHED",
}
