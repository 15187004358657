import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { BotMapping } from "../../api/model";
import * as api from "../../api/api";
import { Link } from "react-router-dom";

export default function AdminBotMapping() {
  const [mapping, setMapping] = useState<BotMapping[] | undefined>();

  useEffect(() => {
    api.getBotMapping().then(setMapping);
  }, []);

  if (mapping === undefined) {
    return <div>Loading...</div>;
  }

  const sortedMapping = [...mapping].sort((a, b) =>
    a.username.localeCompare(b.username)
  );

  return (
    <Table>
      <thead>
        <tr>
          <th>Username</th>
          <th>Bot name</th>
        </tr>
      </thead>
      <tbody>
        {sortedMapping.map((m) => (
          <tr key={m.botId}>
            <td>{m.username}</td>
            <td>
              <Link to={`/my-bots/${m.botId}`}>{m.botName}</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
