function handleResponse(response: Promise<Response>): Promise<object> {
  return response.then((resp) =>
    resp.ok ? resp.json() : resp.text().then((error) => Promise.reject(error))
  );
}

export function getApiText(url: RequestInfo | URL) {
  return fetch(url, {
    credentials: "same-origin",
  }).then((resp) => resp.text());
}

export function getApi(url: RequestInfo | URL) {
  return handleResponse(
    fetch(url, {
      credentials: "same-origin",
    })
  );
}

export function postApiRaw(url: RequestInfo | URL, data: any = {}) {
  return fetch(url, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": getCsrfToken(),
    },
    body: JSON.stringify(data),
  });
}

export function postApi(url: RequestInfo | URL, data: any = {}) {
  return handleResponse(postApiRaw(url, data));
}

export function putApi(url: RequestInfo | URL, data: object = {}) {
  return handleResponse(
    fetch(url, {
      credentials: "same-origin",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-XSRF-TOKEN": getCsrfToken(),
      },
      body: JSON.stringify(data),
    })
  );
}

export function deleteApiRaw(url: RequestInfo | URL) {
  return fetch(url, {
    credentials: "same-origin",
    method: "DELETE",
    headers: {
      "X-XSRF-TOKEN": getCsrfToken(),
    },
  });
}

function getCookie(cname: string) {
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookies = decodedCookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let idx = cookies[i].indexOf(`${cname}=`);
    if (idx >= 0) {
      return cookies[i].substring(idx + cname.length + 1);
    }
  }
  return "";
}

let CSRF_TOKEN = getCookie("XSRF-TOKEN");

export function getCsrfToken(): string {
  if (!CSRF_TOKEN) {
    CSRF_TOKEN = getCookie("XSRF-TOKEN");
  }
  return CSRF_TOKEN;
}
