import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { CompileTask } from "../../api/model";
import * as api from "../../api/api";

export default function AdminTasksCompile() {
  const [tasks, setTasks] = useState<CompileTask[] | undefined>();

  function updateTasks() {
    api.getCompileTasks().then(setTasks);
  }

  useEffect(updateTasks, []);

  if (tasks === undefined) {
    return <div>Loading...</div>;
  }

  if (tasks.length == 0) {
    return <div>No tasks in queue</div>;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>ID</th>
          <th>State</th>
          <th>Claim timestamp</th>
          <th>Source code language</th>
          <th>Source code path</th>
        </tr>
      </thead>
      <tbody>
        {tasks.map((task) => (
          <tr key={task.id}>
            <td>{task.id}</td>
            <td>{task.state}</td>
            <td>{task.claimTimestamp?.toLocaleString("de-CH") ?? "-"}</td>
            <td>{task.sourceCodeLanguage}</td>
            <td>
              <a
                target="_blank"
                href={`/api/filestorage/${task.sourceCodePath}/`}
              >
                Code
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
