import { useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import MyBotDetailsForm from "./MyBotDetailsForm";
import NewBotVersionForm from "./NewBotVersionForm";
import VersionDetails from "./VersionDetails";
import { codeStatusToIcon } from "../../utils/bot-status-icons";
import { Bot } from "../../api/model";
import { useLoginInfo } from "../../App";
import * as api from "../../api/api";
import { MyBotsOutletContext } from "./MyBots";

export default function MyBotDetails() {
  const { onMyBotsChange, bots } = useOutletContext<MyBotsOutletContext>();
  const params = useParams();
  const [selectedVersion, setSelectedVersion] = useState<number>(-1);
  const [stateBot, setStateBot] = useState<Bot | undefined>();
  const loginInfo = useLoginInfo();

  useEffect(() => {
    const matchingBots = bots.filter((b) => b.id.toString() == params.botId);
    if (matchingBots.length == 0) {
      if (loginInfo.admin) {
        api.getBot(parseInt(params.botId || "")).then(setStateBot);
      }
      return;
    }

    setStateBot(matchingBots[0]);
  }, [params, bots]);

  if (bots === undefined || stateBot === undefined) {
    return <div>Loading...</div>;
  }

  const bot = stateBot!;

  function onNewVersionSubmitted(id: number) {
    setSelectedVersion(id);
    onMyBotsChange();
  }

  function versionRow(): JSX.Element {
    if (bot.versions.length == 0) {
      return <></>;
    }

    const currentSelectedVersions = bot.versions.filter(
      (version) => version.id == selectedVersion
    );

    const currentSelectedVersion =
      currentSelectedVersions.length > 0
        ? currentSelectedVersions[0]
        : bot.activeVersion != null
        ? bot.activeVersion
        : bot.versions.sort((a, b) => b.version - a.version)[0];

    return (
      <Row className="my-3">
        <Col lg="4">
          <Nav variant="pills" className="flex-column">
            {bot.versions
              .sort((a, b) => b.version - a.version)
              .map((version) => (
                <Nav.Item key={version.id}>
                  <Nav.Link
                    active={version.id == currentSelectedVersion.id}
                    onClick={() => setSelectedVersion(version.id)}
                  >
                    <>
                      {version.version}:{" "}
                      {version.created.toLocaleString("de-CH")}{" "}
                      {codeStatusToIcon(
                        version.status,
                        version.id != currentSelectedVersion.id
                      )}
                      {version.id == bot.activeVersion?.id ? (
                        <i
                          className="bi bi-award"
                          title="this is the active version"
                        ></i>
                      ) : undefined}
                    </>
                  </Nav.Link>
                </Nav.Item>
              ))}
          </Nav>
        </Col>
        <Col lg="8">
          <VersionDetails bot={bot} botVersion={currentSelectedVersion} />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col lg="6">
          <MyBotDetailsForm bot={bot} onMyBotsChange={onMyBotsChange} />
          <div>Rating: {bot.rating}</div>
        </Col>
        <Col lg="6">
          <NewBotVersionForm
            bot={bot}
            onVersionSubmit={onNewVersionSubmitted}
          />
        </Col>
      </Row>
      {versionRow()}
    </>
  );
}
