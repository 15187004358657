import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Tournament, TournamentStatus } from "../../api/model";
import * as api from "../../api/api";
import { useLoginInfo } from "../../App";
import { Button, FormControl } from "react-bootstrap";
import Countdown from "../../components/Countdown";
import TournamentEdit from "./TournamentEdit";
import TournamentFinishedDetails from "./TournamentFinishedDetails";

export default function TournamentDetails() {
  const params = useParams();
  const [tournament, setTournament] = useState<Tournament | undefined>();
  const [editing, setEditing] = useState<boolean>(false);
  const [exportScore, setExportScore] = useState<number>(75);
  const loginInfo = useLoginInfo();

  function updateTournament() {
    if (params.tournamentId == null) {
      return;
    }
    api.getTournament(parseInt(params.tournamentId)).then(setTournament);
  }

  useEffect(updateTournament, [params.tournamentId]);

  if (tournament === undefined) {
    return <div>Loading...</div>;
  }

  function tournamentStatus(): string {
    switch (tournament?.status) {
      case TournamentStatus.SCHEDULED:
        return `Scheduled at ${tournament.start.toLocaleString("de-CH")}.`;
      case TournamentStatus.RUNNING:
        return "Currently running.";
      case TournamentStatus.FINISHED:
        return `Tournament finished, took place at ${tournament.start.toLocaleString(
          "de-CH"
        )}.`;
    }
    return "¯\\_(ツ)_/¯";
  }

  return (
    <div>
      <h1>{tournament.name}</h1>
      <div className="mb-3">{tournamentStatus()}</div>
      <div className="mb-3">{tournament.botCount} registered bots.</div>
      {!editing && tournament.status === TournamentStatus.SCHEDULED && (
        <h2 className="my-5 text-center">
          <Countdown target={tournament.start} />
        </h2>
      )}
      {loginInfo.admin && (
        <div className="mb-3">
          <Button size="sm" onClick={() => setEditing(!editing)}>
            Edit tournament
          </Button>
          {tournament.status === TournamentStatus.FINISHED && (
            <>
              <FormControl
                value={exportScore}
                onChange={(e) =>
                  setExportScore(parseInt(e.currentTarget.value))
                }
                size="sm"
                type="number"
                style={{ display: "inline", width: "50px" }}
                className={"mx-3"}
                placeholder="score"
              />
              <a
                href={api.getExportLinkTournament(
                  tournament.id,
                  "sub3",
                  exportScore
                )}
                target="_blank"
                rel="noreferrer"
                className={"btn btn-secondary btn-sm"}
              >
                Export 'tournament'
              </a>
            </>
          )}
        </div>
      )}
      {editing && (
        <TournamentEdit
          tournament={tournament}
          onTournamentUpdate={setTournament}
          onUpdate={updateTournament}
        />
      )}
      {!editing &&
        (tournament.status !== TournamentStatus.FINISHED ||
          (tournament.hiddenRanking && !loginInfo.admin)) && (
          <div>
            <h2>Maps</h2>
            {tournament.maps.map((m) => (
              <div key={m.id}>
                <Link to={`/play/${m.mapName}`}>{m.mapName}</Link> (score
                weight: {m.scoreWeight})
              </div>
            ))}
          </div>
        )}
      {!editing &&
        tournament.status !== TournamentStatus.SCHEDULED &&
        tournament.hiddenRanking && (
          <div className="my-3">
            The tournament results are currently hidden.
          </div>
        )}
      {!editing &&
        tournament.status === TournamentStatus.FINISHED &&
        (!tournament.hiddenRanking || loginInfo.admin) && (
          <TournamentFinishedDetails tournament={tournament} />
        )}
    </div>
  );
}
