import { useEffect, useState } from "react";

export default function Countdown(props: { target: Date }): JSX.Element {
  const [diff, setDiff] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(
      () => setDiff(props.target.getTime() - new Date().getTime()),
      500
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  let curDiff = Math.abs(props.target.getTime() - new Date().getTime());
  curDiff = Math.floor(curDiff / 1000);
  const seconds = curDiff % 60;
  curDiff = Math.floor(curDiff / 60);
  const minutes = curDiff % 60;
  curDiff = Math.floor(curDiff / 60);
  const hours = curDiff % 24;
  curDiff = Math.floor(curDiff / 24);
  const days = curDiff;

  return (
    <>
      {days > 0 && (
        <>
          {days} day{days > 1 && "s"},{" "}
        </>
      )}
      {(days > 0 || hours > 0) && (
        <>
          {hours < 10 && "0"}
          {hours}:
        </>
      )}
      {(days > 0 || hours > 0 || minutes > 0) && (
        <>
          {minutes < 10 && "0"}
          {minutes}:
        </>
      )}
      {(days > 0 || hours > 0 || minutes > 0 || seconds > 0) && (
        <>
          {seconds < 10 && "0"}
          {seconds}
        </>
      )}
    </>
  );
}
