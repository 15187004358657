import { GameResult } from "../api/model";

export function gameResultToClass(gameResult: GameResult): string {
  switch (gameResult) {
    case GameResult.WIN:
      return "bg-game-win";
    case GameResult.DRAW:
      return "bg-game-draw";
    case GameResult.LOSS:
      return "bg-game-loss";
    case GameResult.PENDING:
      return "bg-game-pending";
  }
}

export function gameResultToDescription(gameResult: GameResult): string {
  switch (gameResult) {
    case GameResult.WIN:
      return "You won";
    case GameResult.DRAW:
      return "Draw";
    case GameResult.LOSS:
      return "You lost";
    case GameResult.PENDING:
      return "Game is pending";
  }
}
