import {
  Bot,
  BotMapping,
  BotVersion,
  BotVersionDetails,
  ChallengePolicy,
  CompileTask,
  Confirmation,
  GameBot,
  LoginInfo,
  NewGame,
  SimulationTask,
  Tournament,
  TournamentMap,
  TournamentOverview,
  UserInfo,
} from "./model";
import {
  deleteApiRaw,
  getApi,
  getApiText,
  postApi,
  postApiRaw,
  putApi,
} from "./fetch-utlis";

export function logout(): Promise<object> {
  return postApiRaw("/api/auth/logout/");
}

export function authMe(): Promise<LoginInfo> {
  return getApi("/api/auth/me/") as Promise<LoginInfo>;
}

export function debugLoginEnabled(): Promise<boolean> {
  return getApi("/api/auth/debuglogin/") as unknown as Promise<boolean>;
}

export function setDebugLogin(username: string): Promise<string> {
  return postApi(
    "/api/auth/debuglogin/",
    username
  ) as unknown as Promise<string>;
}

export function getMyBots(): Promise<Bot[]> {
  return (getApi("/api/bots/") as Promise<Bot[]>).then((bots) =>
    bots.map(handleBotDate)
  );
}

export function addBot(): Promise<Bot> {
  return (postApi("/api/bots/") as Promise<Bot>).then(handleBotDate);
}

export function getBot(botId: number): Promise<Bot> {
  return (getApi(`/api/bots/${botId}/`) as Promise<Bot>).then(handleBotDate);
}

export function randomizeBotName(botId: number): Promise<Bot> {
  return (
    putApi(`/api/bots/${botId}/`, {
      randomizeName: true,
    }) as Promise<Bot>
  ).then(handleBotDate);
}

export function updateBotName(botId: number, newName: string): Promise<Bot> {
  return (
    putApi(`/api/bots/${botId}/`, {
      newName,
    }) as Promise<Bot>
  ).then(handleBotDate);
}

export function updateBotTournament(
  botId: number,
  participateInTournaments: boolean
): Promise<Bot> {
  return (
    putApi(`/api/bots/${botId}/`, {
      participateInTournaments,
    }) as Promise<Bot>
  ).then(handleBotDate);
}

export function updateBotChallengePolicy(
  botId: number,
  challengePolicy: ChallengePolicy
): Promise<Bot> {
  return (
    putApi(`/api/bots/${botId}/`, {
      challengePolicy,
    }) as Promise<Bot>
  ).then(handleBotDate);
}

export function uploadBotVersion(
  botId: number,
  sourceCode: string,
  sourceCodeLanguage: string
): Promise<BotVersion> {
  return (
    postApi(`/api/bots/${botId}/`, {
      sourceCode,
      sourceCodeLanguage,
    }) as Promise<BotVersion>
  ).then(handleBotVersionDate);
}

export function getBotVersion(
  botId: number,
  botVersionId: number
): Promise<BotVersionDetails> {
  return (
    getApi(`/api/bots/${botId}/${botVersionId}/`) as Promise<BotVersionDetails>
  ).then(handleBotVersionDetailsDate);
}

export function getFileStoreString(filename: string): Promise<string> {
  return getApiText(`/api/filestorage/${filename}/`);
}

export function getGameBots(): Promise<GameBot[]> {
  return getApi("/api/games/bots/") as Promise<GameBot[]>;
}

export function getGameBot(botId: number): Promise<GameBot> {
  return getApi(`/api/games/bots/${botId}/`) as Promise<GameBot>;
}

export function getRanking(): Promise<GameBot[]> {
  return getApi("/api/games/ranking/") as Promise<GameBot[]>;
}

export function startGame(
  mapName: string,
  challengerId: number,
  challengedId: number
): Promise<NewGame> {
  return postApi("/api/games/", {
    mapName,
    challenger: challengerId,
    challenged: challengedId,
  }) as Promise<NewGame>;
}

export function getOpenConfirmations(): Promise<Confirmation[]> {
  return getApi("/api/games/confirmations/open/") as Promise<Confirmation[]>;
}

export function getSentConfirmations(): Promise<Confirmation[]> {
  return getApi("/api/games/confirmations/sent/") as Promise<Confirmation[]>;
}

export function replyConfirmation(
  confirmationId: number,
  accept: boolean
): Promise<NewGame> {
  return putApi(`/api/games/confirmations/${confirmationId}/`, {
    accept,
  }) as Promise<NewGame>;
}

export function getTournaments(): Promise<TournamentOverview[]> {
  return (getApi("/api/tournaments/") as Promise<TournamentOverview[]>).then(
    (tournaments) => tournaments.map(handleTournamentDate)
  );
}

export function getTournament(id: number): Promise<Tournament> {
  return (getApi(`/api/tournaments/${id}/`) as Promise<Tournament>).then(
    handleTournamentDate
  );
}

export function addTournament(
  name: string,
  start: Date,
  hidden: boolean,
  hiddenRanking: boolean
): Promise<Tournament> {
  return (
    postApi(`/api/tournaments/`, {
      name,
      startTimestamp: start,
      hidden,
      hiddenRanking,
    }) as Promise<Tournament>
  ).then(handleTournamentDate);
}

export function updateTournament(
  id: number,
  name: string,
  start: Date,
  hidden: boolean,
  hiddenRanking: boolean
): Promise<Tournament> {
  return (
    putApi(`/api/tournaments/${id}/`, {
      name,
      startTimestamp: start,
      hidden,
      hiddenRanking,
    }) as Promise<Tournament>
  ).then(handleTournamentDate);
}

export function deleteTournament(id: number): Promise<void> {
  return deleteApiRaw(`/api/tournaments/${id}/`) as unknown as Promise<void>;
}

export function addTournamentMap(
  id: number,
  mapName: string,
  mapSeed: number,
  scoreWeight: number
): Promise<TournamentMap> {
  return postApi(`/api/tournaments/${id}/`, {
    mapName,
    mapSeed,
    scoreWeight,
  }) as Promise<TournamentMap>;
}

export function updateTournamentMap(
  tid: number,
  id: number,
  mapName: string,
  mapSeed: number,
  scoreWeight: number
): Promise<TournamentMap> {
  return putApi(`/api/tournaments/${tid}/${id}/`, {
    mapName,
    mapSeed,
    scoreWeight,
  }) as Promise<TournamentMap>;
}

export function deleteTournamentMap(tid: number, id: number): Promise<void> {
  return deleteApiRaw(
    `/api/tournaments/${tid}/${id}/`
  ) as unknown as Promise<void>;
}

export function getBotMapping(): Promise<BotMapping[]> {
  return getApi("/api/admin/botmapping/") as Promise<BotMapping[]>;
}

export function getUsers(): Promise<UserInfo[]> {
  return getApi("/api/admin/users/") as Promise<UserInfo[]>;
}

export function updateUser(
  id: number,
  adminPowerEnabled: boolean
): Promise<UserInfo> {
  return putApi(`/api/admin/users/${id}/`, {
    adminPowerEnabled,
  }) as Promise<UserInfo>;
}

export function getCompileTasks(): Promise<CompileTask[]> {
  return (getApi("/api/admin/tasks/compile/") as Promise<CompileTask[]>).then(
    (tasks) => tasks.map(handleTaskDate)
  );
}

export function getSimulationTasks(): Promise<SimulationTask[]> {
  return (
    getApi("/api/admin/tasks/simulation/") as Promise<SimulationTask[]>
  ).then((tasks) => tasks.map(handleTaskDate));
}

export function getExportLinkBot(
  id: number,
  task: string,
  score: number,
  layouts: number
): string {
  return `/api/export/bot/${id}/?task=${task}&score=${score}&layouts=${layouts}`;
}

export function getExportLinkTournament(
  id: number,
  task: string,
  score: number
): string {
  return `/api/export/tournament/${id}/?task=${task}&score=${score}`;
}

function handleBotDate(bot: Bot): Bot {
  if (bot.activeVersion) {
    bot.activeVersion = handleBotVersionDate(bot.activeVersion);
  }
  bot.versions = bot.versions.map(handleBotVersionDate);
  return bot;
}

function handleBotVersionDate(botVersion: BotVersion): BotVersion {
  botVersion.created = new Date(botVersion.created);
  return botVersion;
}

function handleBotVersionDetailsDate(
  botVersionDetails: BotVersionDetails
): BotVersionDetails {
  botVersionDetails.created = new Date(botVersionDetails.created);
  botVersionDetails.games.forEach(
    (game) => (game.created = new Date(game.created))
  );
  return botVersionDetails;
}

function handleTournamentDate<T extends Tournament | TournamentOverview>(
  tournament: T
): T {
  tournament.start = new Date(tournament.start);
  if (tournament.end) {
    tournament.end = new Date(tournament.end);
  }
  return tournament;
}

function handleTaskDate<T extends { claimTimestamp: any | null }>(task: T): T {
  if (task.claimTimestamp != null) {
    task.claimTimestamp = new Date(task.claimTimestamp);
  }
  return task;
}
