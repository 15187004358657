import {
  ChallengePolicy,
  Tournament,
  TournamentRanking as ApiTournamentRanking,
} from "../../api/model";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

interface TournamentRankingWithRank extends ApiTournamentRanking {
  rank: number;
}

export default function TournamentRanking(props: { tournament: Tournament }) {
  const ranking = props.tournament.ranking;
  let rankingWithRanks = [...ranking] as TournamentRankingWithRank[];

  for (let i = 0; i < ranking.length; i++) {
    if (i > 0 && ranking[i - 1].score === ranking[i].score) {
      rankingWithRanks[i].rank = rankingWithRanks[i - 1].rank;
    } else {
      rankingWithRanks[i].rank = i + 1;
    }
  }

  return (
    <Table hover striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Rating</th>
          <th>Score</th>
          <th>Play</th>
        </tr>
      </thead>
      <tbody>
        {rankingWithRanks.map((rank) => (
          <tr
            key={rank.bot.id}
            className={rank.bot.myBot ? "bg-my-bot" : undefined}
          >
            <td>{rank.rank}</td>
            <td>{rank.bot.name}</td>
            <td>{rank.bot.rating}</td>
            <td>{rank.score}</td>
            <td>
              {(rank.bot.challengePolicy !== ChallengePolicy.NONE ||
                rank.bot.myBot) && (
                <Link to={`/play/${rank.bot.id}`}>Play</Link>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
