import { Tournament, TournamentStatus } from "../../api/model";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import React, { useState } from "react";
import * as api from "../../api/api";
import { useNavigate } from "react-router-dom";
import TournamentMapEdit from "./TournamentMapEdit";
import {
  getAllMapCombinations,
  normalizeMapName,
  validateMapName,
} from "../../utils/map-combinations";

export default function TournamentEdit(props: {
  tournament: Tournament;
  onTournamentUpdate: (tournament: Tournament) => void;
  onUpdate: () => void;
}) {
  const [name, setName] = useState<string>(props.tournament.name);
  const [start, setStart] = useState<string>(
    props.tournament.start.toISOString().replace("Z", "")
  );
  const [hidden, setHidden] = useState<boolean>(props.tournament.hidden);
  const [hiddenRanking, setHiddenRanking] = useState<boolean>(
    props.tournament.hiddenRanking
  );
  const [newMapName, setNewMapName] = useState<string>(
    "small-generous-few-spread-yes"
  );
  const [newMapSeed, setNewMapSeed] = useState<number>(
    Math.floor(Math.random() * 1000000000)
  );
  const [newMapScoreWeight, setNewMapScoreWeight] = useState<number>(1);
  const [deleteConfirming, setDeleteConfirming] = useState<boolean>(false);
  const [bulkAdd, setBulkAdd] = useState<string>("");
  const navigate = useNavigate();

  function save() {
    api
      .updateTournament(
        props.tournament.id,
        name,
        new Date(start + "Z"),
        hidden,
        hiddenRanking
      )
      .then(props.onTournamentUpdate);
  }

  function addMap() {
    api
      .addTournamentMap(
        props.tournament.id,
        normalizeMapName(newMapName)!!,
        newMapSeed,
        newMapScoreWeight
      )
      .then(props.onUpdate)
      .then(() => setNewMapSeed(Math.floor(Math.random() * 1000000000)));
  }

  function bulkAddValid(): boolean {
    return (
      bulkAdd
        .split("\n")
        .filter((line) => line.length >= 5)
        .filter((line) => {
          const parts = line.split(":");
          return (
            validateMapName(parts[0]) == null ||
            isNaN(parseFloat(parts[1] || "1"))
          );
        }).length == 0
    );
  }

  function bulkAddMaps() {
    Promise.all(
      bulkAdd
        .split("\n")
        .filter((line) => line.length >= 5)
        .map((line) => {
          const parts = line.split(":");
          return api.addTournamentMap(
            props.tournament.id,
            normalizeMapName(parts[0])!!,
            Math.floor(Math.random() * 1000000000),
            parseFloat(parts[1] || "1")
          );
        })
    ).then(props.onUpdate);
  }

  function deleteTournament() {
    if (!deleteConfirming) {
      setDeleteConfirming(true);
      return;
    }
    api
      .deleteTournament(props.tournament.id)
      .then(() => navigate("/tournaments"));
  }

  return (
    <div className="mb-3">
      <div className="mb-3">
        {deleteConfirming && (
          <Button
            className="me-3"
            variant="success"
            onClick={() => setDeleteConfirming(false)}
            size="sm"
          >
            Abort
          </Button>
        )}
        <Button variant="danger" size="sm" onClick={deleteTournament}>
          {deleteConfirming ? "Confirm deletion" : "Delete tournament"}
        </Button>
      </div>
      <Form action="#">
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </Form.Group>
        {props.tournament.status == TournamentStatus.SCHEDULED && (
          <Form.Group className="mb-3">
            <Form.Label>Start time (UTC)</Form.Label>
            <Form.Control
              value={start}
              type="datetime-local"
              onChange={(e) => setStart(e.currentTarget.value)}
            />
          </Form.Group>
        )}
        <Form.Check
          className="mb-3"
          type="checkbox"
          checked={hidden}
          label="Tournament hidden"
          onChange={(e) => setHidden(e.currentTarget.checked)}
        />
        <Form.Check
          className="mb-3"
          type="checkbox"
          checked={hiddenRanking}
          label="Ranking hidden"
          onChange={(e) => setHiddenRanking(e.currentTarget.checked)}
        />
        <div className="mb-3">
          <Button
            disabled={
              name == props.tournament.name &&
              start == props.tournament.start.toISOString().replace("Z", "") &&
              hidden == props.tournament.hidden &&
              hiddenRanking == props.tournament.hiddenRanking
            }
            onClick={save}
          >
            Update metadata
          </Button>
        </div>
        <h2>Maps</h2>
        {props.tournament.status == TournamentStatus.SCHEDULED && (
          <datalist id="validmapnames">
            {getAllMapCombinations().map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </datalist>
        )}
        {props.tournament.status == TournamentStatus.SCHEDULED &&
          props.tournament.maps.map((m) => (
            <TournamentMapEdit
              key={m.id}
              tournament={props.tournament}
              tournamentMap={m}
              onUpdate={props.onUpdate}
            />
          ))}
        {props.tournament.status == TournamentStatus.SCHEDULED && (
          <Row className="mb-3 align-items-end">
            <FormGroup as={Col} md={6} className={"mb-3"}>
              <Form.Label>Map name</Form.Label>
              <Form.Control
                value={newMapName}
                onChange={(e) => setNewMapName(e.currentTarget.value)}
                isInvalid={validateMapName(newMapName) == null}
                list="validmapnames"
              />
            </FormGroup>
            <FormGroup as={Col} md={2} className={"mb-3"}>
              <Form.Label>Map seed</Form.Label>
              <Form.Control
                value={newMapSeed}
                type="number"
                onChange={(e) => setNewMapSeed(parseInt(e.currentTarget.value))}
              />
            </FormGroup>
            <FormGroup as={Col} md={2} className={"mb-3"}>
              <Form.Label>Score weight</Form.Label>
              <Form.Control
                value={newMapScoreWeight}
                type="number"
                min="0"
                onChange={(e) =>
                  setNewMapScoreWeight(parseFloat(e.currentTarget.value))
                }
              />
            </FormGroup>
            <Col md={2} className={"mb-3"}>
              <Button
                variant="secondary"
                onClick={addMap}
                disabled={validateMapName(newMapName) == null}
              >
                Add map
              </Button>
            </Col>
            <Col />
          </Row>
        )}
        {props.tournament.status == TournamentStatus.SCHEDULED && (
          <h3>Bulk add maps</h3>
        )}
        <Row>
          <Col>
            <pre>
              {props.tournament.maps.map(
                (m) => `${m.mapName}:${m.scoreWeight}\n`
              )}
            </pre>
          </Col>
          {props.tournament.status == TournamentStatus.SCHEDULED && (
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>New maps</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={7}
                  value={bulkAdd}
                  onChange={(e) => setBulkAdd(e.currentTarget.value)}
                />
              </Form.Group>
              <Button
                disabled={bulkAdd.length < 10 || !bulkAddValid()}
                onClick={bulkAddMaps}
              >
                Bulk add maps
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
}
