import { Button, Container, Form } from "react-bootstrap";
import { getCsrfToken } from "../../api/fetch-utlis";
import React, { useEffect, useState } from "react";
import * as api from "../../api/api";

export default function Login() {
  const [debugLoginEnabled, setDebugLoginEnabled] = useState<boolean>(false);
  const [debugUsername, setDebugUsername] = useState<string>("");

  useEffect(() => {
    api.debugLoginEnabled().then(setDebugLoginEnabled);
  }, []);

  function debugUsernameChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const username = e.currentTarget.value;
    setDebugUsername(username);
    api.setDebugLogin(username).then(() => {});
  }

  return (
    <Container className="text-center" style={{ marginTop: "200px" }}>
      <h1>SOI Creativity Task</h1>
      <Form method="POST" action="/api/auth/login/" className="mt-5">
        <Button type="submit" size="lg">
          Sign in with soi.ch
        </Button>
        <input type="hidden" name="_csrf" value={getCsrfToken()} />
        {debugLoginEnabled && (
          <Form.Control
            className="mt-5"
            placeholder="debug username"
            value={debugUsername}
            onChange={debugUsernameChanged}
          />
        )}
      </Form>
    </Container>
  );
}
