import { useEffect, useState } from "react";
import { ChallengePolicy, GameBot } from "../../api/model";
import * as api from "../../api/api";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

interface GameBotWithRank extends GameBot {
  rank: number;
}

export default function Ranking() {
  const [ranking, setRanking] = useState<GameBot[] | undefined>();

  useEffect(() => {
    api.getRanking().then(setRanking);
  }, []);

  if (ranking === undefined) {
    return <div>Loading...</div>;
  }

  let rankingWithRanks = [...ranking] as GameBotWithRank[];

  for (let i = 0; i < ranking.length; i++) {
    if (i > 0 && ranking[i - 1].rating == ranking[i].rating) {
      rankingWithRanks[i].rank = rankingWithRanks[i - 1].rank;
    } else {
      rankingWithRanks[i].rank = i + 1;
    }
  }

  return (
    <div>
      <h1>Ranking</h1>
      <Table hover striped>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Rating</th>
            <th>Play</th>
          </tr>
        </thead>
        <tbody>
          {rankingWithRanks.map((bot) => (
            <tr key={bot.id} className={bot.myBot ? "bg-my-bot" : undefined}>
              <td>{bot.rank}</td>
              <td>{bot.name}</td>
              <td>{bot.rating}</td>
              <td>
                {(bot.challengePolicy != ChallengePolicy.NONE || bot.myBot) && (
                  <Link to={`/play/${bot.id}`}>Play</Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
