import {
  Button,
  ButtonGroup,
  Form,
  InputGroup,
  ToggleButton,
} from "react-bootstrap";
import { Bot, ChallengePolicy } from "../../api/model";
import React, { useEffect, useState } from "react";
import * as api from "../../api/api";
import { challengePolicyToDescription } from "../../utils/bot-status-utils";
import { useLoginInfo } from "../../App";

export default function MyBotDetailsForm(props: {
  bot: Bot;
  onMyBotsChange: () => void;
}) {
  const [botName, setBotName] = useState<string>(props.bot.name);

  useEffect(() => {
    setBotName(props.bot.name);
  }, [props.bot.name]);

  const loginInfo = useLoginInfo();

  function randomizeName() {
    api.randomizeBotName(props.bot.id).then(props.onMyBotsChange);
  }

  function tournamentChanged(newValue: string) {
    api
      .updateBotTournament(props.bot.id, newValue === "1")
      .then(props.onMyBotsChange);
  }

  function challengePolicyChanged(newValue: string) {
    api
      .updateBotChallengePolicy(
        props.bot.id,
        ChallengePolicy[newValue as keyof typeof ChallengePolicy]
      )
      .then(props.onMyBotsChange);
  }

  function saveBotName() {
    api.updateBotName(props.bot.id, botName).then(props.onMyBotsChange);
  }

  return (
    <Form className="mt-3" action="#">
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <InputGroup>
          <Button
            type="button"
            size="sm"
            variant="secondary"
            onClick={randomizeName}
            disabled={props.bot.activeVersion != null}
          >
            Randomize
          </Button>
          <Form.Control
            value={botName}
            disabled={!loginInfo.admin}
            onChange={(e) => setBotName(e.currentTarget.value)}
            title="You can randomize the name only as long as you did not upload any code"
          />
          {loginInfo.admin && (
            <Button
              type="button"
              size="sm"
              variant="primary"
              onClick={saveBotName}
              disabled={botName === props.bot.name}
            >
              Save
            </Button>
          )}
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Participate in tournaments with this bot</Form.Label>
        <div>
          <ButtonGroup>
            <ToggleButton
              id="tournament-participation-1"
              type="radio"
              variant="outline-success"
              value="1"
              checked={props.bot.participateInTournaments}
              onChange={(e) => tournamentChanged(e.currentTarget.value)}
              name="tournament-participation"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              id="tournament-participation-0"
              type="radio"
              variant="outline-danger"
              value="0"
              checked={!props.bot.participateInTournaments}
              onChange={(e) => tournamentChanged(e.currentTarget.value)}
              name="tournament-participation"
            >
              No
            </ToggleButton>
          </ButtonGroup>
        </div>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Allow others to play against this bot</Form.Label>
        <div>
          <ButtonGroup>
            <ToggleButton
              id="challenge-policy-all"
              type="radio"
              variant="outline-success"
              value={ChallengePolicy.ALL}
              checked={props.bot.challengePolicy === ChallengePolicy.ALL}
              onChange={(e) => challengePolicyChanged(e.currentTarget.value)}
              name="challenge-policy"
              title={challengePolicyToDescription(ChallengePolicy.ALL)}
            >
              Everyone
            </ToggleButton>
            <ToggleButton
              id="challenge-policy-confirm"
              type="radio"
              variant="outline-warning"
              value={ChallengePolicy.CONFIRM}
              checked={props.bot.challengePolicy === ChallengePolicy.CONFIRM}
              onChange={(e) => challengePolicyChanged(e.currentTarget.value)}
              name="challenge-policy"
              title={challengePolicyToDescription(ChallengePolicy.CONFIRM)}
            >
              Confirm
            </ToggleButton>
            <ToggleButton
              id="challenge-policy-none"
              type="radio"
              variant="outline-danger"
              value={ChallengePolicy.NONE}
              checked={props.bot.challengePolicy === ChallengePolicy.NONE}
              onChange={(e) => challengePolicyChanged(e.currentTarget.value)}
              name="challenge-policy"
              title={challengePolicyToDescription(ChallengePolicy.NONE)}
            >
              No one
            </ToggleButton>
          </ButtonGroup>
        </div>
      </Form.Group>
      {loginInfo.admin && (
        <div className="mb-3">
          <a
            href={api.getExportLinkBot(props.bot.id, "sub1", 10, 1)}
            target="_blank"
            rel="noreferrer"
            className={"btn btn-secondary me-3"}
          >
            Export 'rules'
          </a>
          <a
            href={api.getExportLinkBot(props.bot.id, "sub2", 15, 5)}
            target="_blank"
            rel="noreferrer"
            className={"btn btn-secondary me-3"}
          >
            Export 'smart'
          </a>
        </div>
      )}
    </Form>
  );
}
