export function getMapSizes(): string[] {
  return ["small", "medium", "large"];
}

export function getMapIcings(): string[] {
  return ["little", "medium", "much"];
}

export function getMapPours(): string[] {
  return ["few", "medium", "many"];
}

export function getMapCentralness(): string[] {
  return ["spread", "medium", "concentrated"];
}

export function getAllMapCombinations(): string[] {
  return getMapSizes().flatMap((size) =>
    getMapIcings().flatMap((icing) =>
      getMapPours().flatMap((pours) =>
        getMapCentralness().map(
          (centralness) => `${size}-${icing}-${pours}-${centralness}`
        )
      )
    )
  );
}

export function normalizeMapName(mapName: string): string | null {
  const parts = validateMapName(mapName);
  if (parts == null) {
    return null;
  }
  return `${parts.size}-${parts.icing}-${parts.pours}-${parts.centralness}`;
}

export function validateMapName(mapName: string): MapNameParts | null {
  const parts = mapName.split("-");
  const size = parts[0];
  const icing = parts.length > 1 ? parts[1] : "medium";
  const pours = parts.length > 2 ? parts[2] : "medium";
  const centralness = parts.length > 3 ? parts[3] : "medium";

  if (getMapSizes().indexOf(size) == -1) {
    return null;
  }
  if (getMapIcings().indexOf(icing) == -1) {
    return null;
  }
  if (getMapPours().indexOf(pours) == -1) {
    return null;
  }
  if (getMapCentralness().indexOf(centralness) == -1) {
    return null;
  }
  return {
    size,
    icing: icing,
    pours: pours,
    centralness,
  };
}

export interface MapNameParts {
  size: string;
  icing: string;
  pours: string;
  centralness: string;
}
