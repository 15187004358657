export const LANGUAGES = [
  "cpp",
  "python",
  "kotlin",
  "java",
  "rust",
  "javascript",
];

export function languageCodeToLanguage(code: string): string {
  switch (code) {
    case "cpp":
      return "C++ 11.4.0";
    case "python":
      return "Python 3.10.12";
    case "kotlin":
      return "Kotlin 1.7.10";
    case "java":
      return "Java 11.0.24";
    case "rust":
      return "Rust 1.80.1";
    case "javascript":
      return "JavaScript (Node 12.22.9)";
  }
  return "Unknown Language";
}

export function guessLanguageByExtension(
  extension: string
): string | undefined {
  switch (extension) {
    case "cpp":
    case "cxx":
      return "cpp";
    case "py":
      return "python";
    case "kt":
    case "kts":
      return "kotlin";
    case "java":
      return "java";
    case "rs":
      return "rust";
    case "js":
      return "javascript";
  }
}
