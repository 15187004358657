import { Bot, BotVersionStatus, ChallengePolicy, GameBot } from "../api/model";
import { challengePolicyToDescription } from "./bot-status-utils";

export function codeStatusToIcon(
  status: BotVersionStatus,
  color: boolean
): JSX.Element {
  switch (status) {
    case BotVersionStatus.READY:
      return (
        <i
          title="ready to play"
          className="bi bi-check-circle"
          style={color ? { color: "green" } : undefined}
        ></i>
      );
    case BotVersionStatus.FAILED_COMPILATION:
      return (
        <i
          title="compilation failed"
          className="bi bi-x-circle"
          style={color ? { color: "red" } : undefined}
        ></i>
      );
    case BotVersionStatus.FAILED_TESTING:
      return (
        <i
          title="verification failed"
          className="bi bi-x-circle"
          style={color ? { color: "red" } : undefined}
        ></i>
      );
    case BotVersionStatus.COMPILING:
      return (
        <i
          title={`compilation in progress`}
          className="bi bi-info-circle"
          style={color ? { color: "gray" } : undefined}
        ></i>
      );
    case BotVersionStatus.TESTING:
      return (
        <i
          title={`verification in progress`}
          className="bi bi-info-circle"
          style={color ? { color: "gray" } : undefined}
        ></i>
      );
    default:
      return (
        <i
          title={`in queue`}
          className="bi bi-info-circle"
          style={color ? { color: "gray" } : undefined}
        ></i>
      );
  }
}

export function botActiveVersionStatusToIcon(
  bot: Bot,
  color: boolean
): JSX.Element {
  if (!bot.activeVersion) {
    return (
      <i
        title="no valid code uploaded yet"
        className="bi bi-dash-circle"
        style={color ? { color: "orange" } : undefined}
      ></i>
    );
  }
  return codeStatusToIcon(bot.activeVersion.status, color);
}

export function botCodeStatusToIcon(bot: Bot, color: boolean): JSX.Element {
  if (bot.versions.length == 0) {
    return botActiveVersionStatusToIcon(bot, color);
  }
  const latestVersion = bot.versions.sort((a, b) => b.version - a.version)[0];
  if (latestVersion.id == bot.activeVersion?.id) {
    return botActiveVersionStatusToIcon(bot, color);
  }
  return (
    <>
      {botActiveVersionStatusToIcon(bot, color)}{" "}
      {codeStatusToIcon(latestVersion.status, color)}
    </>
  );
}

export function tournamentToIcon(bot: Bot): JSX.Element {
  if (bot.participateInTournaments) {
    return (
      <i
        title="registered for tournaments"
        className="bi bi-check-circle"
        style={{ color: "green" }}
      ></i>
    );
  }
  return (
    <i
      title="not registered for tournaments"
      className="bi bi-dash-circle"
      style={{ color: "red" }}
    ></i>
  );
}

export function challengePolicyToIcon(bot: Bot | GameBot): JSX.Element {
  switch (bot.challengePolicy) {
    case ChallengePolicy.NONE:
      return (
        <i
          title={challengePolicyToDescription(ChallengePolicy.NONE)}
          className="bi bi-dash-circle"
          style={{ color: "red" }}
        ></i>
      );
    case ChallengePolicy.CONFIRM:
      return (
        <i
          title={challengePolicyToDescription(ChallengePolicy.CONFIRM)}
          className="bi bi-exclamation-circle"
          style={{ color: "orange" }}
        ></i>
      );
    case ChallengePolicy.ALL:
      return (
        <i
          title={challengePolicyToDescription(ChallengePolicy.ALL)}
          className="bi bi-check-circle"
          style={{ color: "green" }}
        ></i>
      );
  }
}
